import { createNewInstance } from '../utils/axios'
import { JwtResponse } from '../utils/local_storage/login.storage'


export const sendLoginLink = async (email: string): Promise<ApiResponse> => {
  return createNewInstance().post('auth/login_link', { email })
}

export const getJwtToken = async (code: string | string[]): Promise<string> => {
  const res: ApiResponse = await createNewInstance().post('auth/verify_code', { code })
  return (res.data as JwtResponse).jwt
}
