import React, { useState, ReactElement, useEffect, useContext } from 'react'
import Button from '@material-ui/core/Button'

import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import EmailIcon from '@material-ui/icons/Mail'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TextField, InputAdornment, Snackbar } from '@material-ui/core'
import Alert, { Color } from '@material-ui/lab/Alert'
import { navigate } from 'gatsby'
import BoatAtSea from '../static/images/boat_at_sea.jpeg'
import { sendLoginLink, getJwtToken } from '../services/auth.service'
import { colors } from '../styles/colors'
import { SplashScreenData, GlobalContext } from '../contexts/global.store'
import { SplashScreenComponent } from './splash-screen.component'
import { getLogoUrl, getDefaultLogoUrl } from '../utils/logo'
import polyfills from '../utils/polyfills'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${BoatAtSea})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    color: colors.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
  logo: {
    width: '128px',
  },
  signInText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  field: {
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  progressInvisible: {
    visibility: 'hidden',
  },
  disclaimer: {
    fontStyle: 'italic',
    width: '225px',
    textAlign: 'center',
  },
}))

export default function LoginComponent(): ReactElement {
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const [message, setMessage] = useState<{message: string, type: Color}>({ message: '', type: 'success' })
  const [splashState, setSplashState] = useState<SplashScreenData>({ displayText: 'Loading ...', visible: false })
  const [{ theme }, globalDispatch] = useContext(GlobalContext)
  const [logoSrc, setLogoSrc] = useState(getLogoUrl(theme?.themeId))
  const [email, setEmail] = useState('')
  const getTokenFromQuery = async (code: string): Promise<void> => {
    try {
      const jwtToken = await getJwtToken(code)
      setSplashState({ visible: false, displayText: '' })
      globalDispatch({ type: 'LOGIN', jwtToken })
      navigate('/login') // clears url of the code for login so that "isLoggedIn" redirects to home.
    } catch (err) {
      setSplashState({ visible: false, displayText: '' })
      setMessage({ type: 'error', message: 'The link is invalid or expired. Use the field above to resend login link to your email address.' })
    }
  }

  useEffect(() => {
    const urlParams = polyfills.URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (code) {
      setSplashState({ displayText: 'Logging you in! ...', visible: true })
      getTokenFromQuery(code)
    }
  }, [])

  const [{ jwtToken }] = useContext(GlobalContext)

  const isLoggedIn = (): void => {
    const urlParams = polyfills.URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    if (jwtToken && !code) navigate('/')
  }

  useEffect(isLoggedIn, [jwtToken])

  const sendLink = async (): Promise<void> => {
    setLoading(true)
    try {
      await sendLoginLink(email)
      setMessage({ type: 'success', message: 'Email sent if account exists. (be sure to use your work email address)' })
    } catch (err) {
      setMessage({ type: 'error', message: `${err.status}` })
    }
    setLoading(false)
  }
  return (
    <Grid container component="main" className={classes.root}>
      <SplashScreenComponent splashScreenState={splashState} />
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <div className={classes.avatar}>
            <img
              src={logoSrc}
              onError={(): void => setLogoSrc(getDefaultLogoUrl())}
              alt="Logo"
              className={classes.logo}
            />
          </div>
          <Typography component="h1" variant="h5" className={classes.signInText}>
            Sign In
          </Typography>
          <TextField
            // label="Email Address"
            className={classes.field}
            defaultValue=""
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>): void => {
              if (event.key === 'Enter') sendLink()
            }}
            onChange={(e): void => setEmail(e.target.value)}
            placeholder="Email Address"
            InputProps={{
              startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>,
            }}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={sendLink}
          >
            Send Login Link
          </Button>
          <CircularProgress
            className={clsx({ [classes.progressInvisible]: !isLoading })}
          />
          <Snackbar
            open={!!message.message}
            autoHideDuration={3000}
            onClose={(): void => {
              setMessage({ message: '', type: 'success' })
            }}
          >
            <Alert severity={message.type}>{message.message}</Alert>
          </Snackbar>
          <span className={classes.disclaimer}> Resend your login link to your work email address</span>
        </div>
      </Grid>
    </Grid>
  )
}
