import React, { ReactElement } from 'react'

import { AppComponent } from '../components/app/app.component'
import HeadComponent from '../components/head.component'
import LoginComponent from '../components/login.component'

export default function Login(): ReactElement {
  const LoginContent = (): ReactElement => {
    return (
      <div>
        <HeadComponent title="Login" />
        <LoginComponent />
      </div>
    )
  }
  return (
    <AppComponent hasLayout={false}>
      <LoginContent />
    </AppComponent>
  )
}
